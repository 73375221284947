import {ArraySchema, ObjectSchema, createRequestHook} from 'react-enty';
import {
    creativeItemSchema,
    creativeListSchema,
    liveReleaseItemSchema,
    releaseItemSchema,
    releaseListSchema
} from './EntitySchema';
import {RpcRequest, DataEntryRpc} from '~/core/data/Endpoints';
import * as RulesApi from '~/rules/data/RulesApi';
import LegacyApi from './LegacyApi';
import * as ApiForEntities from '~/entity-editor/data/ApiForEntities';
import {createPollingGenerator} from 'bigdatr-style';
import * as ChangesetApi from '~/feature/ChangesetEditor/ChangesetApi';

export enum SupportedCountry {
    AU = 'Australia',
    NZ = 'New Zealand'
}

const creativeMakeCreateUrl = createRequestHook({
    name: 'generateSignedUploads',
    schema: new ArraySchema(new ObjectSchema({})),
    request: RpcRequest('creativeSignedUploadUrl')
});

const creativeMakeUpdateUrl = createRequestHook({
    name: 'generateUpdateUploads',
    schema: new ArraySchema(new ObjectSchema({})),
    request: RpcRequest('creativeUpdateSignedUrl')
});

const creativeList = createRequestHook({
    name: 'creativeList',
    schema: creativeListSchema,
    request: RpcRequest('creativeList')
});

const creativeItem = createRequestHook({
    name: 'creativeItem',
    schema: creativeItemSchema,
    request: RpcRequest('creativeItem')
});

const creativeItemHistory = createRequestHook({
    name: 'creativeItemHistory',
    request: RpcRequest('creativeItemHistory')
});

export const wordCloud = createRequestHook({
    name: 'wordCloud',
    schema: new ObjectSchema({}),
    request: async (vars: RpcType['creativeWordCloudRequest']['payload'], meta) => {
        const pollingData = createPollingGenerator({
            createRequest: async () => {
                // kick off job, and pass in job.id to `pollRequest`
                return await RpcRequest('creativeWordCloudRequest')(vars, meta);
            },
            pollRequest: async (hashedKey) => {
                // see if the status is good
                const response = await RpcRequest('creativeWordCloudPoll')({hashedKey}, meta);
                return response;
            },
            pollWhile: (data) => data.status !== 'COMPLETED'
        });

        let lastPayload: Awaited<ReturnType<typeof pollingData['next']>>['value'] | null = null;
        for await (const payload of pollingData) {
            lastPayload = payload;
        }
        return lastPayload;
    }
});

const creativeUpdate = createRequestHook({
    name: 'creativeUpdate',
    schema: creativeItemSchema,
    request: RpcRequest('creativeUpdate')
});

const creativesReprocess = createRequestHook({
    name: 'creativesReprocess',
    request: RpcRequest('creativesReprocess')
});

const allocateCreative = createRequestHook({
    name: 'allocateCreative',
    schema: new ObjectSchema({}),
    request: RpcRequest('allocateCreative')
});

//
// Spots

const spotUpsert = createRequestHook({
    name: 'spotUpsert',
    request: async (payload: Payload<'spotCreate'> | Payload<'spotUpdate'>, meta) => {
        if ('id' in payload)
            // Updates can't change the creative id so need to remove it
            // the only way to keep ts happy is to explicitly state the fields for update
            return RpcRequest('spotUpdate')(
                {
                    id: payload.id,
                    page: payload.page,
                    size: payload.size,
                    placementType: payload.placementType,
                    publicationId: payload.publicationId,
                    date: payload.date
                },
                meta
            );
        return RpcRequest('spotCreate')(payload, meta);
    }
});

const spotRemove = createRequestHook({
    name: 'spotRemove',
    request: RpcRequest('spotDelete')
});

const spotList = createRequestHook({
    name: 'spotList',
    schema: new ArraySchema(new ObjectSchema({})),
    request: RpcRequest('spotList')
});

const creativeSchedule = createRequestHook({
    name: 'creativeSchedule',
    schema: new ObjectSchema({}),
    request: RpcRequest('creativeSchedule')
});

const releaseItem = createRequestHook({
    name: 'releaseItem',
    schema: releaseItemSchema,
    request: RpcRequest('releaseGet')
});

const releaseUpdateLiveAndPrepare = createRequestHook({
    name: 'releaseUpdateLiveAndPrepare',
    schema: releaseItemSchema,
    request: async (vars: RpcType['releaseUpdate']['payload'], meta) => {
        const response = await RpcRequest('releaseUpdate')(vars, meta);
        return RpcRequest('releasePrepare')({id: response.id}, meta);
    }
});

const releaseTimeline = createRequestHook({
    name: 'releaseTimeline',
    schema: releaseListSchema,
    request: RpcRequest('releaseTimeline')
});

const releaseSyncStatus = createRequestHook({
    name: 'releaseSyncStatus',
    schema: releaseItemSchema,
    request: RpcRequest('releaseSyncStatus')
});

const releaseSearch = createRequestHook({
    name: 'releaseSearch',
    schema: releaseListSchema,
    request: RpcRequest('releaseSearch')
});

const releasePublish = createRequestHook({
    name: 'releasePublish',
    schema: new ObjectSchema({
        live: liveReleaseItemSchema,
        releaseItem: releaseItemSchema
    }),
    request: async (vars: RpcType['releasePublish']['payload'], meta) => {
        const response = await RpcRequest('releasePublish')(vars, meta);
        return {live: response, releaseItem: response};
    }
});

const releaseCreate = createRequestHook({
    name: 'releaseCreate',
    schema: releaseItemSchema,
    request: RpcRequest('releaseCreate')
});

const releaseUpdate = createRequestHook({
    name: 'releaseUpdate',
    schema: releaseItemSchema,
    request: RpcRequest('releaseUpdate')
});

const releasePrepare = createRequestHook({
    name: 'releasePrepare',
    schema: releaseItemSchema,
    request: RpcRequest('releasePrepare')
});

const releaseGetPublished = createRequestHook({
    name: 'releaseGetPublished',
    schema: liveReleaseItemSchema,
    request: RpcRequest('releaseGetPublished')
});

const buildSearch = createRequestHook({
    name: 'buildSearch',
    request: RpcRequest('buildSearch')
});

export type RpcType = DataEntryRpc;
export type Response<T extends keyof RpcType> = RpcType[T]['response'];
export type Payload<T extends keyof RpcType> = RpcType[T]['payload'];

export const Rpc = {
    ...ApiForEntities,
    ...RulesApi,
    ...ChangesetApi,
    allocateCreative,
    buildSearch,
    creativeItem,
    creativeItemHistory,
    creativeList,
    creativesReprocess,
    creativeSchedule,
    creativeUpdate,
    creativeMakeCreateUrl,
    creativeMakeUpdateUrl,
    wordCloud,
    releaseCreate,
    releaseGetPublished,
    releaseItem,
    releaseUpdateLiveAndPrepare,
    releasePrepare,
    releasePublish,
    releaseSearch,
    releaseTimeline,
    releaseUpdate,
    releaseSyncStatus,
    spotList,
    spotRemove,
    spotUpsert
};

export default LegacyApi;
