import ViewerTeam from '../team/ViewerTeam';
import User from './User';
import Industry from '../industry/data/Industry';

class VIEWER_HAS_NO_TEAMS extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'VIEWER_HAS_NO_TEAMS';
    }
}

export default class Viewer {
    currentTeamId: string;
    id: string;
    teams: ViewerTeam[];
    user: User;
    forecastCutoff: string;
    forecastCutoffDigital: string;
    maxDate: string;

    constructor(data: {
        id: string;
        user: User;
        teams: ViewerTeam[];
        currentTeamId: string;
        forecastCutoff: string;
        forecastCutoffDigital: string;
        maxDate: string;
    }) {
        this.currentTeamId = data.currentTeamId;
        this.id = data.id;
        this.teams = data.teams;
        this.user = data.user;
        this.forecastCutoff = data.forecastCutoff;
        this.forecastCutoffDigital = data.forecastCutoffDigital;
        this.maxDate = data.maxDate;
    }

    get currentTeam() {
        const team = this.teams.find((ii) => ii.id === this.currentTeamId) || this.teams[0];
        if (!team) throw new VIEWER_HAS_NO_TEAMS();
        return team;
    }

    /** Takes an industry list, and returns the same list with accurate `hasAccess` getter */
    mapIndustriesWithMediaValueAccess(industryList: Industry[]): Industry[] {
        const mediaValueProduct = this.currentTeam.productMap.Australia?.MediaValue;
        if (!mediaValueProduct) return [];
        return industryList.map(
            ({id, label}) =>
                new Industry({
                    id,
                    name: label,
                    hasAccess: (mediaValueProduct.industryIds || []).includes(id)
                })
        );
    }
}
