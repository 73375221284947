import type {OperatorExpression} from '~/filters/data/parseFilters';
import {isDatePickerKey, isKeywordKey, isPublicationKey, isTextFieldKey} from '../filterKeys';

export function equalityOperatorToEnglish(operator: OperatorExpression['operator']) {
    switch (operator) {
        case '!=':
            return 'is not';
        case 'range':
            return 'between';
        case '=':
            return 'is';
        case '>=':
            return 'from';
        case '<=':
            return 'until';
        case 'contains':
            return 'contains exact characters';
        case 'containsExact':
            return 'contains phrase';
        case 'containsAll':
            return 'contains all words';
        case 'containsAny':
            return 'contains any words';
        case 'exists':
            return 'is not blank';
        case 'notExists':
            return 'is blank';
        case 'query':
            return 'query';
        case 'simple_query':
            return 'simple query';

        default:
            return operator;
    }
}

export function defaultOperatorType(key: string) {
    if (isKeywordKey(key)) return 'contains';
    if (isTextFieldKey(key)) return 'containsAll';
    return '=';
}

// based on bigdatr-api-dataentry/src/schemas/creativeFilters.ts
export function getEqualityOperatorOptions(attribute: string) {
    const existsKeys = ['notExists', 'exists'];
    if (isKeywordKey(attribute)) return ['=', '!=', 'contains', ...existsKeys];
    if (isPublicationKey(attribute)) return ['=', '!=', ...existsKeys];
    if (isDatePickerKey(attribute)) return ['=', '>=', '<=', 'range', ...existsKeys];
    if (isTextFieldKey(attribute))
        return ['containsAny', 'containsAll', 'containsExact', 'contains', ...existsKeys, 'query'];
    return ['=', '!=', ...existsKeys];
}

// Checking number of double quotes, apparently opensearch thinks string is valid as long as it has
// even number of double quotes
export function validateOpenSearchQueryString(input: string): boolean {
    const numberOfDoubleQuotes = input.split('"').length - 1;

    return numberOfDoubleQuotes % 2 === 0;
}
