import styled from 'styled-components';
import React, {useState, useEffect, ComponentProps} from 'react';
import {Virtuoso} from 'react-virtuoso';
import {Absolute, Box, Flex, FlexColumn, Relative} from '../../../layout';
import Input from '../Input';
import Loader from '../Loader';
import ButtonRefresh from '../ButtonRefresh';
import Text from '../Text';
import Button from '../Button';
import useDropdown from '../../util/useDropdown';
import useSelect from '../../util/useSelect';
import Portal from '../Portal';
import {UNATTRIBUTABLE_LABEL} from '../chart/data/ChartProperty';
import ButtonPill from '../ButtonPill';
import {Control, Layout} from './DropdownLayoutAffordances';
import useDebounce from '../../util/useDebounce';
import useDropdownFilterBubbler from './useDropdownFilterBubbler';
import Tooltip from '../Tooltip';
import Link from '../Link';
import Skeleton from '../Skeleton';
import {IconPaste} from '../../../icon';

const Title = styled.div<{disabled?: boolean}>`
    display: flex;
    align-items: center;
    cursor: ${(_) => (_.disabled ? 'not-allowed' : 'pointer')};
`;

const DropdownMenu = styled.div<{width: string; fixedHeight: boolean}>`
    background: ${(p) => p.theme.colors.background};
    z-index: 2;
    padding: 1rem;

    @media (max-width: ${(_) => _.theme.breakpoints.mdMaxWidth}) {
        /* Override useDropdown's style */
        position: fixed !important;
        left: 0 !important;
        top: 0 !important;
        height: 100vh;
        width: 100vw;
    }

    @media (min-width: ${(_) => _.theme.breakpoints.md}) {
        border-radius: 0.5rem;
        border: 2px solid ${(_) => _.theme.colors.outline};
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        display: block;
        ${(p) => (p.fixedHeight ? 'height: 34rem;' : '')}
        max-width: 100vw;
        position: absolute;
        width: ${(p) => p.width};
    }
`;

type CallbackProps = {onClose: () => void};

interface BaseProps<T> {
    //
    // Control props
    //
    /** This opens the dropdown */
    control: string | ((props: {isOpen: boolean}) => React.ReactNode);
    /** Adds a dashed border to `control`. Has an effect only if `control` is a `string` */
    dashedBorder?: boolean;
    /** Whether the control content should stay persistent. If `true`, will use the return
     *  value of `control`. Otherwise the control will depend on the selected values. */
    persistentControl?: boolean;
    /** Render a smaller control */
    small?: boolean;
    /** if `control` === string, this will prepend the control to the selected values.
     *
     * E.g. if `control` is `Industry`, the rendered text will be `Industry is Automotive` */
    showControlName?: boolean;
    controlLoading?: boolean;
    rootControlStyle?: React.CSSProperties;

    //
    // Option props
    //
    /** Option label - Used to determine search results */
    label: (item: T) => string;

    /** Option id */
    id?: (item: T) => string;
    /** Option render - Used if you want better control over the content of each item. */
    renderOption?: (item: T) => React.ReactNode;
    optionDisabled?: (item: T) => boolean;
    optionOnClick?: (item: T) => void;

    //
    // Component props
    //
    /** Prevent the dropdown from opening */
    controlDisabled?: boolean;
    isLoading?: boolean;
    /** Renders a custom component in case of errors */
    renderError?: React.ReactNode;
    /** Allow calling `onChange` with all options selected */
    returnAll?: boolean;
    /** Disable submitting when no options are selected */
    returnNone?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    setIsOpen?: (next: boolean) => void;
    initialOpen?: boolean;
    /** Alignment of the open dropdown. Defaults to `left` */
    align?: 'left' | 'right';
    /** Button text for filtering items. Defaults to `Filter` */
    successAction?: string;
    /** Disables the button which filters selected options */
    disableFilterButton?: (selectedOptions: T[]) => {
        submitButtonDisabled: boolean;
        submitButtonTooltip?: string;
    };
    /** Text for the clear button. Defaults to `Clear` */
    clearLabel?: React.ReactNode;
    /** Override width of an open dropdown. Defaults to `26rem` */
    dropdownWidth?: string;
    /** Whether to show the dropdown's content. If `false`, will show only `aboveContent`,
     * `bottomLeftContent` and the dropdown's action buttons. Defaults to `true`. */
    showContent?: boolean;
    /** Whether to hide `Select all` and `Clear all` buttons. Defaults to `false`. */
    hideBatchActions?: boolean;
    onPaste?: (pastedText: string) => Promise<T[]>;
    pasteTooltip?: string;

    refreshProps?: ComponentProps<typeof ButtonRefresh>;
    rootLayoutStyle?: React.CSSProperties;

    //
    // Extra content
    //
    /** Places a full width node above all content. */
    topMostContent?: ({onClose}: CallbackProps) => React.ReactNode;
    topRightContent?: ({onClose}: CallbackProps) => React.ReactNode;
    /** Content to display on the left of 'Cancel' and 'Filter' buttons */
    bottomLeftContent?: ({
        onClose,
        selectedOptionsLength
    }: CallbackProps & {selectedOptionsLength: number}) => React.ReactNode;
    /** Content to display if the `options` array is empty. */
    noOptionsContent?: ({onClose}: CallbackProps) => React.ReactNode;
    /** Content to append to every `option` in the list.  */
    additionalOptionContent?: ({onClose, option}: CallbackProps & {option: T}) => React.ReactNode;

    /** Config that will enable 'pagination' mode for the Dropdown
     *
     *  Changes behaviour such as
     * - disables internal filtering
     * - disables resetting selected items when options array changes
     * - when `returnAll = false`, will only return an empty array when all items have been selected and `hasNextPage` = false
     * */
    pagination?: {
        /** Key when changed will trigger selected items to bubble up */
        queryKey: string;
        /** Key when changed will reset internal selected state */
        resetKey: string;
        hasNextPage: boolean;
        onNextPage: (index?: number) => void;
        /** Debounced callback when search value changes */
        onSearch: (search: string) => void;
        isPageLoading: boolean;
    };
}

interface MultiProps<T> extends BaseProps<T> {
    multi: true;

    onChange: (items: Array<T>) => void;
    options: Array<T>;
    value: Array<T>;
}

interface SingleProps<T> extends BaseProps<T> {
    multi: false;

    onChange: (item?: T) => void;
    options: Array<T>;
    value?: T;
    /** if multi=false and this prop is set to true, clicking on an option from the dropdown will
     * select it and call onChange */
    selectingValueCallsOnChange?: boolean;
}

export default function DropdownFilter<T>(props: SingleProps<T> | MultiProps<T>) {
    const {
        bottomLeftContent,
        control,
        dashedBorder,
        id = (ii) => (ii as any).name,
        initialOpen = false,
        isLoading = false,
        label,
        onOpen = () => null,
        optionDisabled = () => false,
        optionOnClick,
        options,
        pagination,
        renderOption,
        returnAll = false,
        returnNone = true,
        successAction = 'Filter',
        topRightContent,
        additionalOptionContent,
        noOptionsContent,
        persistentControl = false,
        align = 'left',
        clearLabel = 'Clear',
        topMostContent,
        dropdownWidth = '26rem',
        showContent = true,
        hideBatchActions = false,
        small = false,
        showControlName = false,
        renderError,
        disableFilterButton
    } = props;

    const [selectedOptions, setSelectedOptions] = useState(
        new Array<T>().concat(props.value || [])
    );

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        pagination?.onSearch(debouncedSearch);
    }, [debouncedSearch]);

    useEffect(() => {
        setSelectedOptions(new Array<T>().concat(props.value || []));
    }, [
        // Ensure internal state is up to date with external changes
        JSON.stringify(new Array<T>().concat(props.value ?? []).map((value) => id(value))),
        // When paginating a list, the options array will change when fetching/appending new pages, though we don't want to reset the selected state when that happens
        pagination?.resetKey ?? options
    ]);

    const {open, setOpen, node, menuNode, menuStyle} = useDropdown({
        offset: {top: 8},
        onClose: () => {
            setSelectedOptions(new Array<T>().concat(props.value || []));
            setSearch('');
            props.onClose?.();
        },
        onOpen: () => {
            onOpen?.();
        },
        initialOpen,
        align
    });

    const bubbler = useDropdownFilterBubbler({
        open,
        search: pagination ? debouncedSearch : search,
        options,
        optionId: id,
        selectedOptions,
        pagination
    });

    const {
        onSelectAll,
        onSelectNone,
        options: filterItems
    } = useSelect({
        options: bubbler.options,
        id,
        multi: props.multi,
        value: selectedOptions,
        onChange: (next) => setSelectedOptions(new Array<T>().concat(next)),
        onSelectAll: () => {
            const filteredOptions = bubbler.options.filter((option) =>
                label(option).toLowerCase().includes(search)
            );

            setSelectedOptions(filteredOptions);
        }
    });

    // If pagination is provided, component expects options to be filtered externally with onSearch
    // Otherwise filter the items internally.
    const filteredOptions = pagination
        ? filterItems
        : filterItems.filter(({option}) => {
              const value = label(option) || '';
              if (value.length === 0 || value === UNATTRIBUTABLE_LABEL) return false;
              if (!search) return true;
              return value.toLowerCase().includes(search.toLowerCase());
          });

    const hasAllOptions = pagination ? !pagination.hasNextPage && !search : true;

    const onSubmit = () => {
        if (
            selectedOptions.length === options.length &&
            props.multi &&
            !returnAll &&
            hasAllOptions
        ) {
            // Reset the filter when the user selects all available options
            setSelectedOptions([]);
            props.onChange([]);
        } else {
            if (props.multi === true) {
                props.onChange(selectedOptions);
            } else {
                props.onChange(selectedOptions[0]);
            }
        }
        setOpen(false);
        setSearch('');
    };

    const hasValue = props.multi ? props.value.length > 0 : props.value && id(props.value);
    const controlDisabled = Boolean(props.controlDisabled || props.controlLoading);

    function renderControl(hasValue: boolean) {
        if (props.controlLoading) {
            return (
                <Box borderRadius="50vh" overflow="hidden">
                    <Skeleton height="2rem" width="10rem" data-testid="Skeleton" />
                </Box>
            );
        }

        // if non-string control is provided, use that
        if (typeof control === 'function') return control({isOpen: open});

        let controlChildren: React.ReactNode;

        if (
            persistentControl || // Ensure the control stays the same, even if values are selected
            (typeof props.value === 'string' && props.value.trim().length === 0) || // empty string as value
            typeof props.value === 'undefined' || // no value selected for multi={false}
            (Array.isArray(props.value) && props.value.length === 0) // no value selected for multi={true}
        ) {
            controlChildren = control;
        } else {
            // when values are selected, we always show the first value
            const displayedValue = props.multi ? props.value[0] : props.value;
            // use function from props to create a string from the selected value
            const labeledValue = label(displayedValue);

            // sometimes we prepend the control name before selected values to make it clear what
            // type of items are selected (e.g. you can render control as `Industry is "Automotive"`)
            let beforeValue = '';
            let afterValue = '';
            if (showControlName) {
                beforeValue = `${control} is "`;
                afterValue = '"';
            }

            // if there is more than 1 selected value in multi mode, we show the first value
            // plus a number of remaining selected values
            let afterMulti = '';
            if (props.multi && props.value.length > 1) {
                afterMulti = ` +${props.value.length - 1} more`;
            }

            controlChildren = (
                <span>
                    {beforeValue}
                    {labeledValue}
                    {afterValue}
                    {afterMulti}
                </span>
            );
        }

        return (
            <ButtonPill
                hasValue={hasValue}
                disabled={controlDisabled}
                dashedBorder={dashedBorder}
                small={small}
            >
                <Title disabled={controlDisabled}>{controlChildren}</Title>
            </ButtonPill>
        );
    }

    const contentProps = {onClose: () => setOpen(false)};

    const noOptionsNode = noOptionsContent?.(contentProps);
    const noOptions = options.length === 0 && noOptionsNode ? noOptionsNode : null;

    const topContent = topMostContent?.(contentProps);

    const selectingValueCallsOnChange = !props.multi && props.selectingValueCallsOnChange;

    const optionList = () => {
        return (
            <Box height="100%">
                <Virtuoso
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                    endReached={pagination?.onNextPage}
                    data={filteredOptions}
                    itemContent={(_, {selected, option, onToggle}) => {
                        const ll = label(option);
                        const disabled = optionDisabled(option);
                        const additionalContent = additionalOptionContent?.({
                            ...contentProps,
                            option
                        });

                        return (
                            <Flex
                                alignItems="stretch"
                                minHeight="2rem"
                                style={{
                                    cursor: disabled ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <Flex
                                    alignItems="center"
                                    onClick={() => {
                                        if (selectingValueCallsOnChange) {
                                            if (selected) props.onChange(undefined);
                                            else props.onChange(option);
                                            setOpen(false);
                                            setSearch('');
                                        }
                                        optionOnClick?.(option);
                                        if (disabled) return;
                                        onToggle();
                                    }}
                                    flexGrow={1}
                                    px={2}
                                    overflow="hidden"
                                >
                                    <Box flexBasis="1.5em" flexShrink={0}>
                                        {selected ? '✔︎' : ' '}
                                    </Box>
                                    {renderOption ? (
                                        renderOption(option)
                                    ) : (
                                        <Text ellipsis title={ll} children={ll} />
                                    )}
                                </Flex>
                                {additionalContent && <Flex pr={2}>{additionalContent}</Flex>}
                            </Flex>
                        );
                    }}
                    components={{
                        Footer: () => {
                            return (
                                <Flex pl="3rem">
                                    {
                                        // have no idea why it throws this eslint error
                                        // eslint-disable-next-line react/prop-types
                                        pagination?.isPageLoading && (
                                            <Text textStyle="strong">Loading...</Text>
                                        )
                                    }
                                </Flex>
                            );
                        }
                    }}
                />
            </Box>
        );
    };

    const {submitButtonDisabled, submitButtonTooltip} =
        disableFilterButton?.(selectedOptions) || {};

    const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        if (!props.onPaste) return;
        const pastedText = e.clipboardData.getData('text');

        props.onPaste(pastedText).then((itemsToSelect) => {
            if (itemsToSelect.length > 0) {
                setSelectedOptions(itemsToSelect);
                if (props.multi) props.onChange(itemsToSelect);
                else props.onChange(itemsToSelect[0]);
                setSearch('');
                setOpen(false);
            }
        });
    };

    return (
        <Layout isOpen={open} style={props.rootLayoutStyle}>
            <Control
                disabled={controlDisabled}
                isOpen={open}
                ref={node}
                onClick={() => {
                    if (!controlDisabled) {
                        setOpen(!open);
                        if (!open) {
                            onOpen();
                        }
                    }
                }}
                style={props.rootControlStyle}
            >
                {renderControl(hasValue)}
            </Control>

            {open && (
                <Portal>
                    <DropdownMenu
                        ref={menuNode}
                        style={menuStyle}
                        width={dropdownWidth}
                        fixedHeight={showContent}
                        data-testid="DropdownFilter"
                    >
                        <Flex flexDirection="column" height="100%">
                            {topContent && <Box>{topContent}</Box>}
                            {showContent && (
                                <>
                                    <Flex mb={2} justifyContent="space-between">
                                        <Flex alignItems="center">
                                            {!hideBatchActions && (
                                                <>
                                                    {props.multi && (
                                                        <Box mr={2}>
                                                            {hasAllOptions ? (
                                                                <Link onClick={onSelectAll}>
                                                                    Select All
                                                                </Link>
                                                            ) : (
                                                                <Tooltip
                                                                    content="Some items are not loaded"
                                                                    children="Select all"
                                                                    cursor="not-allowed"
                                                                />
                                                            )}
                                                        </Box>
                                                    )}
                                                    <Link mr={2} onClick={onSelectNone}>
                                                        {clearLabel}
                                                    </Link>
                                                </>
                                            )}
                                        </Flex>
                                        {topRightContent?.(contentProps)}
                                    </Flex>

                                    <Flex flexShrink={0} mb={3} gap={2}>
                                        <Relative flexGrow={1}>
                                            <Input
                                                placeholder="Search"
                                                type="search"
                                                value={search}
                                                onChange={setSearch}
                                                autoFocus
                                                clearable
                                                onPaste={onPaste}
                                            />
                                            {props.onPaste && (
                                                <Absolute
                                                    top={0}
                                                    right=".75rem"
                                                    bottom={0}
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <Tooltip content={props.pasteTooltip}>
                                                        <IconPaste />
                                                    </Tooltip>
                                                </Absolute>
                                            )}
                                        </Relative>
                                        {props.refreshProps && (
                                            <ButtonRefresh {...props.refreshProps} />
                                        )}
                                    </Flex>
                                    {/* Browser Bug: Safari needs flex=1 not flexGrow=1 to cope with AutoSizer. Couldn't find out why though. */}
                                    <FlexColumn flex="1" border="2px solid" borderColor="outline">
                                        {isLoading ? (
                                            <Loader fadeInTime="0.5s" />
                                        ) : (
                                            renderError ?? noOptions ?? optionList()
                                        )}
                                    </FlexColumn>
                                </>
                            )}
                            <Flex justifyContent="space-between" alignItems="center" mt={3} gap={3}>
                                <Box>
                                    {bottomLeftContent?.({
                                        ...contentProps,
                                        selectedOptionsLength: selectedOptions.length
                                    })}
                                </Box>
                                {selectingValueCallsOnChange ? null : (
                                    <Flex gap={2}>
                                        <Button secondary onClick={() => setOpen(false)}>
                                            Cancel
                                        </Button>
                                        <Tooltip content={submitButtonTooltip}>
                                            <Button
                                                data-testid="dropdown-filter-submit"
                                                onClick={onSubmit}
                                                disabled={
                                                    submitButtonDisabled ||
                                                    (!returnNone && selectedOptions.length === 0)
                                                }
                                            >
                                                {successAction}
                                            </Button>
                                        </Tooltip>
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>
                    </DropdownMenu>
                </Portal>
            )}
        </Layout>
    );
}
